import React, { useState } from "react";
import CustomCheckBox from "../CustomCheckBox";
import Collapsible from "react-collapsible";

function SegmentSection({
  disableSegmentBox,
  segmentList,
  selectedSegments,
  expandedSegments,
  setExpandedSegments,
  handleBySegmentTrigger,
  addMetrics,
  setDisableSegmentBox,
  setSelectedSegments,
  setSegmentList,
  setDisabledCompanyBox,
  paramId,
  texonomyList,
  setTexonomyList,
  expandedTexonomy,
  setExpandedTexonomy,
  selectedTexonomy,
  setSelectedTexonomy,
  handleChevron,
  expandedGroup,
}) {
  const [texonomyExpand, setTexonomyExpand] = useState(true);
  const [segmentExpand, setSegmentExpand] = useState(true);
  return (
    <div
      className="col-md-3 add-segments-block d-flex"
      style={{
        backgroundColor: disableSegmentBox
          ? "rgba(255, 255, 255, 0.5)"
          : "rgba(243, 243, 243, 0.5)",
        cursor: disableSegmentBox ? "pointer" : "not-allowed",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <div>
        <div
          className={`text-grey block-title font-weight-normal mb-0 d-flex justify-between align-items-center`}
        >
          <h6 className="text-grey font-weight-normal mb-0">Select Segments</h6>
        </div>

        <div
          className={
            !texonomyList.length || !segmentExpand || !texonomyExpand
              ? "only-segment-collaps-main-div"
              : "segment-collaps-main-div"
          }
        >
          {segmentList.length > 0 && (
            <Collapsible
              open={segmentExpand}
              trigger={
                <div
                  onClick={() => setSegmentExpand(!segmentExpand)}
                  className="segment-collaps-div"
                >
                  <span className="span_1">TBR Texonomy Views</span>

                  <div className="d-flex g-10 align-item-center">
                    <span className="faq-add-icon material-icons-outlined material-icon mr-2">
                      {segmentExpand ? "expand_less" : "expand_more"}
                    </span>
                  </div>
                </div>
              }
            >
              <div className="segments-tree custom-checkbox-tree">
                <CustomCheckBox
                  nodes={segmentList}
                  checked={selectedSegments}
                  expanded={expandedSegments}
                  onExpand={(expanded) => setExpandedSegments(expanded)}
                  onCheck={(checked) => handleBySegmentTrigger(checked)}
                  key={"Segments"}
                  disabled={
                    !disableSegmentBox ||
                    texonomyList.filter((i) => i.checked.length).length
                  }
                  is_tree={true}
                  width={"380px"}
                />
              </div>
            </Collapsible>
          )}

          {texonomyList.length > 0 && (
            <Collapsible
              open={texonomyExpand}
              trigger={
                <div
                  onClick={() => setTexonomyExpand(!texonomyExpand)}
                  className="segment-collaps-div"
                >
                  <span className="span_1">TBR Segmented Views</span>

                  <div className="d-flex g-10 align-item-center">
                    <span className="faq-add-icon material-icons-outlined material-icon mr-2">
                      {texonomyExpand ? "expand_less" : "expand_more"}
                    </span>
                  </div>
                </div>
              }
            >
              <div className="segments-tree custom-checkbox-tree">
                {texonomyList
                  .filter((i) => i.children.length)
                  .sort((a, b) => a.group_name.localeCompare(b.group_name))
                  .map((item) => {
                    let cousionDisabled = texonomyList.filter(
                      (i) =>
                        i.children.length &&
                        i.id !== item.id &&
                        i.checked.length
                    ).length;
                    let isExpanded = expandedGroup.includes(item.id);
                    return (
                      <>
                        <div className="d-flex align-item-center">
                          <span
                            data-testid={`checkbox-chevron`}
                            className={`material-icons-outlined icon ${
                              isExpanded ? "expanded-true" : "expanded-false"
                            }`}
                            style={{
                              color: isExpanded ? "#046CD9" : "#84909E",
                            }}
                            onClick={() => handleChevron(item.id, !isExpanded)}
                          >
                            {isExpanded ? "expand_more" : "chevron_right"}
                          </span>
                          <span className="segment_group_name_span">
                            {item.group_name}
                          </span>
                        </div>

                        {isExpanded && (
                          <CustomCheckBox
                            nodes={item.children}
                            checked={item.checked}
                            expanded={expandedTexonomy}
                            onExpand={(expanded) =>
                              setExpandedTexonomy(expanded)
                            }
                            onCheck={(checked) => setTexonomyList(checked)}
                            key={"Segments"}
                            disabled={
                              !disableSegmentBox ||
                              selectedSegments?.length ||
                              cousionDisabled
                            }
                            is_tree={true}
                            width={"380px"}
                            group_id={item.id}
                            all_data={texonomyList}
                          />
                        )}
                      </>
                    );
                  })}
              </div>
            </Collapsible>
          )}
        </div>
      </div>
      {disableSegmentBox && (
        <>
          <div
            style={{
              // position: "absolute",
              bottom: "0%",
              height: "30px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: "0 10 0 10",
              justifyContent: "space-between",
              gap: "10px",
              background: "#ffffff",
            }}
          >
            <div
              style={{
                width: "50%",
                backgroundColor: "#FBFBFB",
                height: "inherit",
                display: "flex",
                alignItems: "center",
                padding: "0px 10px",
                gap: "10px",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setDisableSegmentBox(false);
                setDisabledCompanyBox(true);
                if (!paramId) {
                  setSelectedSegments([]);
                  setSelectedTexonomy([]);
                }
                setSegmentList([]);
                setTexonomyList([]);
              }}
            >
              <span
                className="material-icons-outlined add-icon"
                // data-testid={`checkbox-arrow-segment`}
                style={{ color: "#606B77", fontSize: "18px", width: "18px" }}
              >
                arrow_backward
              </span>
              <span
                style={{ fontSize: "14px", fontWeight: 400, color: "#606B77" }}
              >
                Back
              </span>
            </div>
            <div
              style={{
                width: "50%",
                backgroundColor: "#DCEDFF",
                height: "inherit",
                display: "flex",
                alignItems: "center",
                padding: "0px 10px",
                gap: "10px",
                justifyContent: "center",
                cursor:
                  selectedSegments.length ||
                  texonomyList.filter((i) => i.checked.length).length
                    ? "pointer"
                    : "not-allowed",
              }}
              onClick={(e) => {
                if (
                  selectedSegments.length > 0 ||
                  texonomyList.filter((i) => i.checked.length).length
                ) {
                  addMetrics(e);
                }
              }}
            >
              <span
                style={{ fontSize: "14px", fontWeight: 400, color: "#2E6AD2" }}
              >
                Next
              </span>
              <span
                className="material-icons-outlined add-icon"
                style={{ color: "#2E6AD2", fontSize: "18px" }}
              >
                arrow_forward
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SegmentSection;
